/**
 * @param  {string} gaCode - the tracking code (GTM code)
 */
export const loadGA = (gaCode) => {
	const s = document.createElement('script');
	s.src = `https://www.googletagmanager.com/gtag/js?id=${gaCode}`;
	s.async = 1;
	const x = document.getElementsByTagName('script')[0];
	x.parentNode.insertBefore(s, x);
	const s2 = document.createElement('script');
	// this is my hacky way of adding a script to the document head
	s2.text = `
		window.dataLayer = window.dataLayer || [];
		function gtag() {
			window.dataLayer.push(arguments);
		}
		gtag('js', new Date());
		gtag('config', '${gaCode}');
	`;
	x.parentNode.insertBefore(s2, x);
};
/**
 * A helper for tracking non-automatically tracked GA events.
 * See https://support.google.com/analytics/topic/9756175?hl=en&ref_topic=9143232 for more info.
 * @param {Object} - params
 * @param  {string} params.eventType - the name of the event (GA created or custom)
 * @param  {Object} params.data - any data associated with the event.
 */
export const trackEvent = ({ eventType, data }) => {
	const { gtag } = window;
	gtag('event', eventType, data);
};

/**
 * @param  {string} dsn - the Sentry DSN
 * @param  {string} version - the package version
 * @param  {string} env - environment
 */
export	const loadSentry = (dsn, version, env) => {
	const asyncSentry = document.createElement('script');
	asyncSentry.src = `https://js.sentry-cdn.com/${dsn}.min.js`;
	asyncSentry.crossOrigin = 'anonymous';
	asyncSentry['data-lazy'] = 'no';
	asyncSentry.id = 'sentry-async-script';
	// eslint-disable-next-line no-undef
	asyncSentry.onload = () => Sentry.onLoad(() => {
		// eslint-disable-next-line no-undef
		Sentry.init({
			environment: env,
			debug: env === 'development',
			release: `buddy.insure@${version}`,
		});
	});
	asyncSentry.async = true;
	const head = document.querySelector('head');
	const scripts = head.getElementsByTagName('script');
	// this puts Sentry as the very first script.
	head.insertBefore(asyncSentry, scripts[0]);
};
