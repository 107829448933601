/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable space-before-function-paren */
/* eslint-disable prefer-rest-params */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable quotes */
// eslint-disable-next-line no-unused-vars

import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { loadGA, loadSentry } from '../models/analytics';

const fbAppID = process.env.GATSBY_FB_APP_ID;
const GTM_CODE = process.env.GATSBY_GTM_CODE;
const SENTRY_DSN = process.env.GATSBY_SENTRY_DSN;
const ENV = process.env.GATSBY_ENV;

const defaults = {
	title: 'The insurance gateway for software applications | Buddy',
	description: 'Buddy is the insurance gateway for software companies. Our drop-in snippet enables you to embed any insurance product into your transaction flow in minutes. Connect once and leave bulky API integrations behind forever.',
	author: 'Buddy',
	keywords: 'insurance gateway, insurance processing, insurance on the internet, insurance embed, insurance app, how to sell insurance online, how to add insurance to my business',
};

const SEO = ({
	children,
	title = defaults.title,
	description = defaults.description,
	keywords = defaults.keywords,
	// location is passed in from reach router, no need to grab it manually.
	location: { pathname },
	image,
}) => {
	const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
					version
        }
      }
      defaultImage: file(relativePath: { regex: "/buddy-banner.jpg/" }) {
        childImageSharp {
          resize(width: 1200) {
            src
            height
            width
          }
        }
      }
    }
  `);
	const { siteUrl, version } = data.site.siteMetadata;
	const metaImage = image || data.defaultImage.childImageSharp.resize;
	const imageURL = `${siteUrl}${metaImage.src}`;
	const canonicalUrl = `${siteUrl}${pathname}`;
	const ogType = 'website';

	// const intercomInit = () => {
	// 	// we modified our own intercom loading script to defer its expensive loading.
	// 	if (typeof window !== 'undefined') {
	// 		window.intercomSettings = {
	// 			app_id: 'e8od9ods',
	// 		};
	// 		const w = window;
	// 		const ic = w.Intercom;
	// 		if (typeof ic === 'function') {
	// 			ic('reattach_activator');
	// 			ic('update', w.intercomSettings);
	// 		} else {
	// 			const d = document;
	// 			var i = function () {
	// 				i.c(arguments);
	// 			};
	// 			i.q = [];
	// 			i.c = function (args) {
	// 				i.q.push(args);
	// 			};
	// 			w.Intercom = i;
	// 			const l = function () {
	// 				const s = d.createElement('script');
	// 				s.type = 'text/javascript';
	// 				s.defer = true;
	// 				s.src = 'https://widget.intercom.io/widget/e8od9ods';
	// 				const x = d.getElementsByTagName('script')[0];
	// 				x.parentNode.insertBefore(s, x);
	// 			};
	// 			l();
	// 		}
	// 	}
	// };

	// const initializeThirdParties = async () => {
	// 	if (typeof window !== 'undefined') {
	// 		intercomInit();
	// 	}
	// };

	// useEffect(() => {
	// 	setTimeout(initializeThirdParties, 6000);
	// }, []);

	const metaTags = [
		{
			name: 'viewport',
			content: 'width=device-width, initial-scale=1',
		},
		{
			name: 'description',
			content: description,
		},
		{
			name: 'keywords',
			content: keywords,
		},
		{
			name: 'icon',
			href: `${siteUrl}/buddy-logo.png`,
		},
		{
			property: 'og:title',
			content: title,
		},
		{
			property: 'og:description',
			content: description,
		},
		{
			name: 'twitter:creator',
			content: defaults.author,
		},
		{
			name: 'twitter:title',
			content: title,
		},
		{
			name: 'twitter:description',
			content: description,
		},
		{
			property: 'og:url',
			content: canonicalUrl,
		},
		{
			property: 'og:type',
			content: ogType,
		},
		{
			property: 'og:image',
			content: imageURL,
		},
		{
			property: 'og:image:width',
			content: metaImage.width,
		},
		{
			property: 'og:image:height',
			content: metaImage.height,
		},
		{
			name: 'twitter:card',
			content: 'summary_large_image',
		},
		{
			property: 'fb:app_id',
			content: fbAppID,
		},
	];
	useEffect(() => {
		loadGA(GTM_CODE);
		loadSentry(SENTRY_DSN, version, ENV);
	}, []);

	return (
		<>
			<Helmet
				htmlAttributes={{
					lang: 'en',
				}}
				title={title}
				link={[
					{
						rel: 'canonical',
						href: canonicalUrl,
					},
					{
						rel: 'preconnect',
						href: 'https://fonts.buddyinsurance.com',
						crossOrigin: true,
					},
					{
						rel: 'preconnect',
						href: 'https://www.googletagmanager.com',
						crossOrigin: true,
					},
				]}
				meta={metaTags}
			>
				<script type="application/ld+json">
					{`{
              "@context": "https://schema.org",
              "@type": "Organization",
              "url": "${siteUrl}",
              "logo": "${siteUrl}/buddy-logo.png"
            }`}
				</script>
			</Helmet>
			{children}
		</>
	);
};

export default SEO;
